@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-style: normal;
}

body {
  color: #2f3542;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

ol,
ul {
  list-style: none;
}

button,
input {
  outline: none;
}

.swiper-pagination-bullet {
  background-color: #c8d0d6 !important;
}
.swiper-pagination-bullet-active {
  background-color: #90a1ac !important;
}
.swiper-pagination {
  bottom: 0px !important;
}

